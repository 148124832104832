<template>
  <b-card title="Crea un nou allotjament">
    <b-row>
      <!-- Name -->
      <b-col cols="12" sm="6" lg="4" xl="3">
        <b-form-group label="Nom" label-for="name">
          <b-form-input
            id="name"
            v-model="accommodation.name"
            placeholder="Nom"
          />
        </b-form-group>
      </b-col>
      <!-- Accommodation Type -->
      <b-col cols="12" sm="6" lg="4" xl="3">
        <b-form-group label="Tipus" label-for="accommodationType">
          <v-select
            id="accommodationType"
            v-model="accommodation.type"
            placeholder="Selecciona un tipus d'allotjament"
            :options="accommodationTypeOptions"
            :reduce="(option) => option.value"
          />
        </b-form-group>
      </b-col>
      <!-- Tourist Licence Number -->
      <b-col cols="12" sm="6" lg="4" xl="2">
        <b-form-group label="ETV" label-for="touristLicenceNumber">
          <b-form-input
            id="touristLicenceNumber"
            v-model="accommodation.touristLicenceNumber"
            placeholder="Nº ETV"
          />
        </b-form-group>
      </b-col>
      <!-- Capacity -->
      <b-col cols="6" xl="2">
        <b-form-group label="Capacitat" label-for="capacity">
          <b-form-spinbutton
            id="capacity"
            v-model="accommodation.capacity"
            min="1"
            max="20"
            step="1"
            placeholder="--"
          />
        </b-form-group>
      </b-col>
      <!-- Cadastral Reference -->
      <b-col cols="12" sm="6" lg="4" xl="2">
        <b-form-group label="Ref. Catastral" label-for="cadastralReference">
          <b-form-input
            id="cadastralReference"
            v-model="accommodation.cadastralReference"
            placeholder="Nº Referència"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormSpinbutton,
} from "bootstrap-vue";
import { accommodationTypeOptions } from "@/utils/select-options";
import vSelect from "vue-select";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormSpinbutton,
    vSelect,
  },
  data() {
    return {
      accommodation: {
        uuid: uuidv4(),
        active: false,
        name: null,
        cadastralReference: null,
        type: null,
        touristLicenceNumber: null,
        capacity: null,
      },
    };
  },
  computed: {
    accommodationTypeOptions() {
      return accommodationTypeOptions;
    },
  },
  watch: {
    accommodation: {
      deep: true,
      handler() {
        this.$emit("change", this.accommodation);
      },
    },
  },
  created() {
    this.$emit("change", this.accommodation);
  },
  methods: {
    resetInputs() {
      this.accommodation.name = null;
      this.accommodation.cadastralReference = null;
      this.accommodation.type = null;
      this.accommodation.touristLicenceNumber = null;
      this.accommodation.capacity = null;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
