<template>
  <div id="new-accommodation-page">
    <b-row>
      <b-col>
        <info-card @change="onAccommodationUpdated" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <location-card @change="onLocationUpdated" />
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-2 d-flex justify-content-center justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="onCancelButtonClicked">
          Cancel·la
        </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="onSaveButtonClicked">
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import { notifyError } from "@/utils/methods";
import InfoCard from "@/views/accommodations/new-accommodation/components/InfoCard.vue";
import LocationCard from "@/views/accommodations/new-accommodation/components/LocationCard.vue";

export default {
  name: "NewAccommodation",
  components: {
    BRow,
    BCol,
    BButton,
    InfoCard,
    LocationCard,
  },
  data() {
    return {
      accommodation: null,
      location: null,
    };
  },
  methods: {
    onAccommodationUpdated(accommodation) {
      this.accommodation = accommodation;
    },
    onLocationUpdated(location) {
      this.location = location;
    },
    onCancelButtonClicked() {
      this.$router.push({ name: "foravila-accommodations-list" });
    },
    onSaveButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("accommodation/createAccommodation", {
          uuid: this.accommodation.uuid,
          active: this.accommodation.active,
          name: this.accommodation.name,
          type: this.accommodation.type,
          cadastralReference: this.accommodation.cadastralReference,
          touristLicenceNumber: this.accommodation.touristLicenceNumber,
          capacity: this.accommodation.capacity,
          location: this.location,
        })
        .then(() =>
          this.$router.push({
            name: "foravila-accommodation-view",
            params: { accommodationUuid: this.accommodation.uuid },
          })
        )
        .catch(() =>
          notifyError(
            "Allotjament no creat",
            "L'allotjament no s'ha pogut crear correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
