<template>
  <b-card id="location-card" title="Ubicació">
    <!-- ADDRESS -->
    <b-row class="mb-3">
      <b-col cols="12" class="mb-2">
        <h5><feather-icon icon="MapIcon" /> Adreça</h5>
      </b-col>
      <b-col cols="12">
        <b-row>
          <!-- COUNTRY -->
          <b-col cols="12" sm="4" xl="3" class="mb-1">
            <h6>País</h6>
            <v-select
              v-model="location.country"
              :options="countryOptions"
              :reduce="(option) => option.value"
            />
          </b-col>

          <!-- REGION -->
          <b-col cols="12" sm="4" xl="3" class="mb-1">
            <h6>Regió</h6>
            <v-select
              v-model="location.region"
              :options="regionOptions"
              :reduce="(option) => option.value"
            />
          </b-col>

          <!-- CITY -->
          <b-col cols="12" sm="4" xl="3" class="mb-1">
            <h6>Ciutat</h6>
            <b-form-input v-model="location.city" />
          </b-col>

          <!-- ZIP -->
          <b-col cols="6" sm="4" xl="3" class="mb-1">
            <h6>Codi Postal</h6>
            <b-form-input v-model="location.zip" />
          </b-col>

          <!-- ADDRESS TYPE -->
          <b-col cols="6" sm="4" xl="3" class="mb-1">
            <h6>Tipus via</h6>
            <v-select
              v-model="location.addressType"
              :options="addressTypeOptions"
              :reduce="(option) => option.value"
            />
          </b-col>

          <template v-if="location.addressType === 'DISSEMINATED'">
            <!-- ADDRESS POLYGON -->
            <b-col cols="6" sm="4" xl="3" class="mb-1">
              <h6>Polígon</h6>
              <b-form-input v-model="location.addressPolygon" />
            </b-col>

            <!-- ADDRESS PLOT -->
            <b-col cols="6" sm="4" xl="3" class="mb-1">
              <h6>Parcel·la</h6>
              <b-form-input v-model="location.addressPlot" />
            </b-col>
          </template>

          <template v-else>
            <!-- ADDRESS NAME -->
            <b-col cols="12" sm="4" xl="3" class="mb-1">
              <h6>Nom via</h6>
              <b-form-input v-model="location.addressName" />
            </b-col>

            <!-- ADDRESS NUMBER -->
            <b-col cols="4" sm="4" xl="3" class="mb-1">
              <h6>Número</h6>
              <b-form-input v-model="location.addressNumber" />
            </b-col>

            <!-- ADDRESS BLOCK -->
            <b-col cols="4" sm="4" xl="3" class="mb-1">
              <h6>Bloc</h6>
              <b-form-input v-model="location.addressBlock" />
            </b-col>

            <!-- ADDRESS PORTAL -->
            <b-col cols="4" sm="4" xl="3" class="mb-1">
              <h6>Portal</h6>
              <b-form-input v-model="location.addressPortal" />
            </b-col>

            <!-- ADDRESS STAIRS -->
            <b-col cols="4" sm="4" xl="3" class="mb-1">
              <h6>Escala</h6>
              <b-form-input v-model="location.addressStairs" />
            </b-col>

            <!-- ADDRESS FLOOR -->
            <b-col cols="4" sm="4" xl="3" class="mb-1">
              <h6>Pis</h6>
              <b-form-input v-model="location.addressFloor" />
            </b-col>

            <!-- ADDRESS DOOR -->
            <b-col cols="4" sm="4" xl="3" class="mb-1">
              <h6>Porta</h6>
              <b-form-input v-model="location.addressDoor" />
            </b-col>
          </template>
        </b-row>
      </b-col>
    </b-row>

    <!-- GPS -->
    <b-row class="mb-1">
      <b-col cols="12" class="mb-2">
        <h5><feather-icon icon="NavigationIcon" /> GPS</h5>
      </b-col>
      <b-col cols="12">
        <b-row class="mb-2">
          <!-- LATITUDE -->
          <b-col cols="6" sm="4" xl="3" class="mb-1">
            <h6>Latitud</h6>
            <b-form-input v-model="location.latitude" />
          </b-col>
          <!-- LONGITUDE -->
          <b-col cols="6" sm="4" xl="3" class="mb-1">
            <h6>Longitud</h6>
            <b-form-input v-model="location.longitude" />
          </b-col>
          <!-- AREA -->
          <b-col cols="12" sm="4" xl="3" class="mb-1">
            <h6>Àrea</h6>
            <v-select
              v-model="location.area"
              :options="areaOptions"
              :reduce="(option) => option.value"
            />
          </b-col>
        </b-row>
        <!-- MAP -->
        <b-row>
          <b-col cols="12">
            <div class="map-container">
              <l-map
                ref="editLocationMap"
                :zoom="zoom"
                :center="editedMapCenter"
                :options="mapOptions"
              >
                <l-control-layers position="topright" />
                <l-tile-layer
                  v-for="tileProvider in tileProviders"
                  :key="tileProvider.name"
                  :name="tileProvider.name"
                  :visible="tileProvider.visible"
                  :url="tileProvider.url"
                  layer-type="base"
                />
                <l-marker
                  :visible="editedMapMarker.visible"
                  :draggable="editedMapMarker.draggable"
                  :lat-lng.sync="editedMapMarker.position"
                />
              </l-map>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput } from "bootstrap-vue";
import { LMap, LTileLayer, LMarker, LControlLayers } from "vue2-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import {
  countryOptions,
  regionOptions,
  addressTypeOptions,
  areaOptions,
} from "@/utils/select-options";
import vSelect from "vue-select";

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  // eslint-disable-next-line global-require
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  // eslint-disable-next-line global-require
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "LocationCard",
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    LMap,
    LTileLayer,
    LMarker,
    LControlLayers,
    vSelect,
  },
  data() {
    return {
      tileProviders: [
        {
          name: "Mapa",
          visible: true,
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        },
        {
          name: "Satèl·lit",
          visible: false,
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        },
      ],
      mapOptions: { scrollWheelZoom: false },
      zoom: 12,
      location: {
        latitude: null,
        longitude: null,
        country: "ES",
        region: "Mallorca",
        city: null,
        zip: null,
        addressType: null,
        addressName: null,
        addressNumber: null,
        addressBlock: null,
        addressPortal: null,
        addressStairs: null,
        addressFloor: null,
        addressDoor: null,
        addressPolygon: null,
        addressPlot: null,
        area: null,
      },
      editedMapMarker: {
        position: { lat: 39.77288941462659, lng: 3.0153393745422363 },
        visible: true,
        draggable: true,
      },
      editedMapCenter: [39.77288941462659, 3.0153393745422363],
    };
  },
  computed: {
    countryOptions() {
      return countryOptions;
    },
    regionOptions() {
      return regionOptions;
    },
    areaOptions() {
      return areaOptions;
    },
    addressTypeOptions() {
      return addressTypeOptions;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    "editedMapMarker.position": function (position) {
      if (position) {
        this.location.latitude = position.lat;
        this.location.longitude = position.lng;
      }
    },
    location: {
      deep: true,
      handler() {
        this.$emit("change", {
          ...this.location,
          latitude: this.location.latitude?.toString(),
          longitude: this.location.longitude?.toString(),
        });
      },
    },
  },
  created() {
    this.$emit("change", this.location);
  },
  methods: {
    resetInputs() {
      this.location.latitude = null;
      this.location.longitude = null;
      this.location.country = null;
      this.location.region = null;
      this.location.city = null;
      this.location.zip = null;
      this.location.addressType = null;
      this.location.addressName = null;
      this.location.addressNumber = null;
      this.location.addressBlock = null;
      this.location.addressPortal = null;
      this.location.addressStairs = null;
      this.location.addressFloor = null;
      this.location.addressDoor = null;
      this.location.addressPolygon = null;
      this.location.addressPlot = null;
      this.location.area = null;
      this.editedMapMarker = {
        position: { lat: 39.77288941462659, lng: 3.0153393745422363 },
        visible: true,
        draggable: true,
      };
      this.editedMapCenter = [39.77288941462659, 3.0153393745422363];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#location-card {
  .vs__dropdown-menu {
    z-index: 1001;
  }
}
.map-container {
  height: 400px;
  width: 100%;
}
</style>
